.case-article-content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 40px;
  border-bottom: 2px solid #34495e;
  

  h5 {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    color: #000;
    padding-bottom: 16px;
    border-bottom: 2px solid #E5E5E5;
    position: relative;
    margin-top: 40px;
    &::after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 2px;
      background:#30A31C;
    }
  }

  & section {
    & h4 {
      font-size: 24px;
      line-height: 32px;
      color: #000;
      padding-left: 28px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 4px;
        height: 100%;
        background-color: #30a31c;
        border-radius: 2px;
        top: 0;
        left: 0;
      }
    }
    & p {
      font-size: 16px;
      line-height: 180%;
      color: #000;
    }
    & strong {
      font-weight: bold;
    }
    & * + p,
    & * + ul,
    & * + iframe,
    & * + a {
      margin-top: 16px;
    }
    & iframe {
      width: 100%;
      aspect-ratio: 2 / 1;
    }
    & a {
      text-decoration-line: underline;
      color: #58b947;
    }
  }
  @include mq(u-sp) {
    margin-top: 24px;
    gap: 24px;
    padding-bottom: 24px;
    & section {
      & h4 {
        font-size: 20px;
      }
      & p {
        font-size: 16px;
        line-height: 150%;
      }
      & * + p {
        margin-top: 16px;
      }
    }
  }
}

.tips-article {
  border-bottom: 2px solid #34495e;
  & > .article-headline {
    padding-bottom: 40px;
  }
  & > * + .article-headline {
    margin-top: 40px;
  }
  @include mq(u-sp) {
    & > .article-headline {
      padding-bottom: 20px;
    }
    & > * + .article-headline {
      margin-top: 38px;
    }
  }
}

.tips-list {
  position: relative;
  padding: 0 0 0 15px!important;
  font-size: 16px!important;
  border-bottom: none!important;
  &::after {
    display: none;
  }
  &:before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 2px;
    background: #30A31C;
  }
  & + p {
    margin: 5px 0 0;
  }
}

.news-article-content {
  h4 {
    margin: 40px 0 0;
    &.tips-list {
      position: relative;
      padding: 0 0 0 15px!important;
      font-size: 16px!important;
      border-bottom: none!important;
      &::after {
        display: none;
      }
      &:before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        top: 15px;
        left: 2px;
        background: #30A31C;
      }
      & + p {
        margin: 5px 0 0;
      }
    }
  }
  & section {
    & h3 {
      margin: 40px 0 0;
      font-size: 24px;
      line-height: 32px;
      color: #000;
      padding-left: 28px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 4px;
        height: 100%;
        background-color: #30a31c;
        border-radius: 2px;
        top: 0;
        left: 0;
      }
    }
    > h4 {
      font-size: 20px;
      line-height: 32px;
      font-weight: bold;
      color: #000;
      padding-bottom: 16px;
      border-bottom: 2px solid #E5E5E5;
      position: relative;
      margin-top: 40px;
      &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 2px;
        background:#30A31C;
      }
    }
  }
}

.case-article-content section {
  .tips-list {
    position: relative;
    padding: 0 0 0 15px!important;
    font-size: 16px!important;
    border-bottom: none!important;
    &::after {
      display: none;
    }
    &:before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 15px;
      left: 2px;
      background: #30A31C;
    }
    & + p {
      margin: 5px 0 0;
    }
  } 
}

.tips-article-content {
  padding: 56px 0;
  border-top: 2px solid #d6dbdf;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .highlight-box {
    margin: 40px 0 0;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    & h4 {
      font-size: 18px;
      font-weight: bold;
      padding: 0;
      margin: 0;
      &:before {
        display: none;
      }
  
    }
    & p {
      font-size: 14px;
    }
    ul {
      margin: 20px 0 0;
      li {
        line-height: 1.5;
        &:not(:first-child) {
          margin: 15px 0 0;
        }
        @include mq(u-sp){
          font-size: 14px;
        }
      }
    }
  }
  .right-text {
    font-size: 14px;
    text-align: right;
  }
  & section {
    padding-bottom: 40px;
    &:not(:last-of-type) {
      border-bottom: solid 2px #d6dbdf;
    }
    & * + p,
    & * + ul,
    & * + iframe,
    & * + table,
    & * + a {
      margin-top: 16px;
    }
    & * + .article-elem {
      margin-top: 40px;
    }
    & h3 {
      font-size: 24px;
      line-height: 32px;
      color: #000;
      padding: 16px 0 16px 24px;
      border-left: 4px solid #30A31C;
      &:not(:first-of-type) {
        margin: 40px 0 0;
      }
    }
    & h3 {
      &:not(:first-of-type) {
        margin: 40px 0 0;
      }
    }
    &:not(.highlight-box){
      > h4 {
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;
        color: #000;
        padding-bottom: 16px;
        border-bottom: 2px solid #E5E5E5;
        position: relative;
        margin-top: 40px;
        &::after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 40px;
          height: 2px;
          background:#30A31C;
        }
      }
    }
    & h5 {
      font-size: 16px;
      margin-top: 24px;
      padding-left: 16px;
      position: relative;
      & + p {
        margin-top: 0;
      }
      &::before{
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background:#30A31C;
      }
    }
    & iframe {
      width: 100%;
      aspect-ratio: 2 / 1;
    }
    & figure {
      margin: 16px 0 0;
      width: 100%;
    }
    & a {
      text-decoration-line: underline;
      color: #58b947;
    }
    & p {
      font-size: 16px;
      line-height: 180%;
      color: #000;
    }
    & table {
      width: 100%;
      // max-width: 580px;
    }
    & table,
    td,
    th {
      border: 1px solid #ccc;
      border-collapse: collapse;
    }
    td,
    th {
      padding: 8px 16px;
    }
    th {
      padding: 8px 16px;
      color: #fff;
      background-color: #34495e;
      text-align: center;
    }
    td {
      word-break: break-all;
      color: #4c4c4c;
    }
    figure {
      text-align: center;
    }
    img {
      width: auto;
    }
    & .reference-data-title {
      text-align: center;
    }
    & .reference-data-notice {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
    }
    & .article-notice {
      margin-top: 40px;
      font-size: 12px;
      line-height: 21px;
      color: #000;
    }
  }
  @include mq(u-sp) {
    gap: 24px;
    padding: 24px 0 0;
    & section {
      padding-bottom: 24px;
      & h3 {
        margin-top: 16px;
        font-size: 20px;
        padding: 0 0 0 17px;
      }
      & h4 {
        font-size: 20px;
      }
      & p {
        font-size: 16px;
        line-height: 150%;
      }
      & * + p {
        margin-top: 16px;
      }
      & * + .article-elem {
        margin-top: 24px;
      }
      & .reference-data-title{
        font-size: 14px;
      }
    }
  }

  section {
    h3,
    h4 {
      &:not(:first-of-type) {
        margin: 40px 0 0
      }
    }
  }
  

}

.news-article-content {
  padding: 40px 0;
  border-bottom: 2px solid #34495e;
  display: flex;
  flex-direction: column;
  gap: 40px;
  & section {
    font-size: 14px;
    & * + p,
    & * + ul,
    & * + iframe,
    & * + table,
    & * + a {
      margin-top: 16px;
    }
    & * + .article-elem {
      margin-top: 40px;
    }
    & h3 {
      font-size: 24px;
      line-height: 32px;
      color: #000;
    }
    & h4{
      font-size: 18px;
      margin-bottom: 12px;
    }
    & strong {
      font-weight: bold;
    }
    & iframe {
      width: 100%;
      aspect-ratio: 2 / 1;
    }
    & figure {
      margin: 16px 0 0;
      width: 100%;
    }
    & a {
      text-decoration-line: underline;
      color: #58b947;
    }
    & p {
      font-size: 16px;
      line-height: 180%;
      color: #000;
    }
    & table {
      width: 100%;
      max-width: 700px;
    }
    & table,
    td,
    th {
      border: 1px solid #ccc;
      border-collapse: collapse;
    }
    td,
    th {
      padding: 8px 16px;
    }

    th {
      padding: 8px 16px;
      color: #fff;
      background-color: #34495e;
      text-align: left;
      width: 120px;
    }
    td {
      word-break: break-all;
      color: #4c4c4c;
    }
    & .reference-data-title {
      text-align: center;
    }
    & .reference-data-notice {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
    }
    & .article-notice {
      margin-top: 40px;
      font-size: 16px;
      line-height: 21px;
      color: #000;
    }
  }
  & .highlight-box {
    margin: 40px 0 0;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    & h4 {
      margin-bottom: 14px;
    }
  }
  .highlight-box {
    margin: 40px 0 0;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    & h4 {
      font-size: 18px;
      font-weight: bold;
      padding: 0;
      margin: 0;
      &:before {
        display: none;
      }
  
    }
    & p {
      font-size: 14px;
    }
    ul {
      margin: 20px 0 0;
      li {
        line-height: 1.5;
        &:not(:first-child) {
          margin: 15px 0 0;
        }
        @include mq(u-sp){
          font-size: 14px;
        }
      }
    }
  }
  @include mq(u-sp) {
    padding: 24px 0 32px;
    gap: 24px;
    & section {
      & th {
        width: 55px;
        font-size: 12px;
        padding: 8px 12px;
        text-align: center;
      }
      & td{
        font-size: 12px;
      }
      & h4 {
        font-size: 18px;
      }
      & p {
        font-size: 12px;
        line-height: 150%;
      }
      & * + p {
        margin-top: 16px;
      }
      & * + .article-elem {
        margin-top: 24px;
      }
      & .highlight-box {
        & h4 {
          // margin-bottom: 16px;
          & p {
            font-size: 16px;
            padding-top: 16px;
          }
        }
      }
      & .article-table {
        & p {
          margin-top: 32px;
        }
      }
    }
  }
}

.article-headline {
  margin-bottom: 40px;
  @include mq(u-sp) {
    margin-bottom: 24px;
  }
}

.article-headline-categorys {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  & > a {
    border: 1px solid #58b947;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0 4px;
    font-size: 12px;
    color: #58b947;
  }
}

.article-headline-heading {
  margin-top: 16px;
  font-size: 32px;
  line-height: 42px;
  font-weight: bold;
  color: #34495e;
  @include mq(u-sp) {
    font-size: 24px;
    line-height: 32px;
  }
}

.article-headline-target {
  margin-top: 16px;
  font-size: 16px;
  line-height: 23px;
  color: #3c495b;
}

.article-headline-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  & > li {
    font-size: 12px;
    color: #30a31c;
  }
}

.article-headline-btn--pdf {
  border: 2px solid #30a31c;
  box-sizing: border-box;
  border-radius: 80px;
  padding: 7px 24px;
  transition: background-color 0.2s;
  display: inline-flex;
  align-items: center;
  & > span {
    line-height: 1;
    font-size: 12px;
    color: #30a31c;
    padding-left: 20px;
    position: relative;
    transition: color 0.2s;
    &::before {
      position: absolute;
      content: "";
      background-image: url("../img/icon/img_icon_pdf.png");
      background-size: contain;
      width: 12px;
      height: 14px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: background-image 0.2s;
    }
  }
  &:hover {
    background-color: #30a31c;
    & > span {
      color: #fff;
      &::before {
        background-image: url("../img/icon/img_icon_pdf_hover.png");
      }
    }
  }
  @include mq(u-sp){
    margin-top: 42px;
    padding: 2px 24px;
  }
}

.article-eyecatch {
  margin-top: 40px;
  @include mq(u-sp) {
    margin-top: 24px;
  }
}

.article-eyecatch-heading {
  background-color: #34495e;
  padding: 24px;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  display: block;
  @include mq(u-sp) {
    font-size: 20px;
    line-height: 30px;
  }
}

.article-eyecatch + .article-eyecatch-heading {
  border-radius: 0px 0px 8px 8px;
}

.case-article-content .highlight-box {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  & h4 {
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    &:before {
      display: none;
    }

  }
  & p {
    font-size: 14px;
    padding-top: 14px;
  }
  ul {
    margin: 20px 0 0;
    li {
      line-height: 1.5;
      &:not(:first-child) {
        margin: 15px 0 0;
      }
      @include mq(u-sp){
        font-size: 14px;
      }
    }
  }
}

.case-article-content {
  h4 {
    margin: 40px 0 0;
    & + p {
      margin: 30px 0 0;
    }
  }
  p {
    & + p {
      margin: 30px 0 0;
    }
  }
}

.wp-caption {
  >p {
    text-align: center;
  }
}

hr {
  width: 100%;
  background: #D6DBDF;
  height: 2px;
}

.section-float {
  @include mq(u-sp){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  >div {
    padding: 0 0 20px 20px;
    float: right;
    @include mq(u-sp){
      float: none;
      padding: 0;
      width: 100%!important;
      img {
        max-width: 100%;
        width: auto!important;
      }
    }
    & + p {
      margin: 0;
    }
  }
  .wp-caption {
    @include mq(u-sp){
      margin: 20px 0 0;
    }
  }
  &-txt {
    padding: 0!important;
    float: none!important;
  }
}

.case-article-content section.section-info {
  >h4 {
    padding: 0;
    &:before {
      display: none;
    }
    &:first-of-type {
      margin: 0;
    }
  }
}

.section-grid {
  display: flex;
  gap: 24px;
  @include mq(u-sp) {
    flex-direction: column;
  }
}

.section-grid-content {
  max-width: 374px;
  @include mq(u-sp) {
    max-width: 100%;
  }
  >h4 {
    &:first-of-type {
      margin: 0;
    }
  }
}

* + .article-table {
  margin-top: 32px;
}

.article-table-name {
  font-size: 18px;
  line-height: 21px;
  color: #000;
}

.article-annotation {
  font-size: 12px;
  line-height: 21px;
  color: #000;
}

.article-table {
  & > * + table {
    margin-top: 16px;
  }
  & > table {
    width: 100%;
    max-width: 580px;
  }
  & > table,
  td,
  th {
    border: 1px solid #ccc;
    border-collapse: collapse;
  }
  td,
  th {
    padding: 8px 16px;
    font-size: 14px;
  }

  th {
    width: 120px;
    padding: 8px 16px;
    color: #fff;
    background-color: #34495e;
  }
  td {
    word-break: break-all;
    color: #4c4c4c;
  }
  @include mq(u-sp){
    p{
      margin-top: 32px;
    }
  }
}

.article-index {
  padding: 55px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-top: 2px solid #d6dbdf;
  text-decoration-line: underline;
  @include mq(u-sp) {
    padding: 24px;
    padding: 40px 0;
  }
}

.article-index-item {
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #000000;
}

.article-author {
  display: flex;
  gap: 32px;
  // border-top: 2px solid #34495e;
  border-bottom: 2px solid #34495e;
  padding: 40px 0;
  @include mq(u-sp) {
    flex-direction: column;
  }
}

.article-author-photo {
  width: 260px;
  flex-shrink: 0;
  @include mq(u-sp) {
    // width: 100%;
    margin: 0 auto;
  }
}

.article-author-profile {
  font-size: 18px;
  color: #000;
}

.article-author-name {
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

.article-author-detail {
  margin-top: 14px;
  font-size: 14px;
  line-height: 21px;
  & > * + p {
    margin-top: 21px;
  }
}

.article-headline-btn-wrap {
  margin-top: 16px;
  text-align: end;
  @include mq(u-sp){
    margin-top: 42px;
  }
}

.article-download{
  padding: 56px 0 20px;
  // border-top: 2px solid #d6dbdf;
  & > p {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }
  @include mq(u-sp) {
    padding: 72px 0 20px;
  }
}

.article-download-btn-wrap,
.article-request-btn-wrap {
  text-align: center;
  margin-top: 16px;
}

.article-download-btn--pdf,
.article-request-btn {
  display: inline-block;
  border: 2px solid #30a31c;
  box-sizing: border-box;
  border-radius: 80px;

  min-width: 228px;
  transition: background-color 0.2s;
  & > span {
    color: #30a31c;
    padding-left: 20px;
    position: relative;
    transition: color 0.2s;
    // &::before {
    //   position: absolute;
    //   content: "";
    //   background-image: url("../img/icon/img_icon_pdf.png");
    //   background-size: contain;
    //   width: 12px;
    //   height: 14px;
    //   top: 50%;
    //   left: 0;
    //   transform: translateY(-50%);
    //   transition: background-image 0.2s;
    // }
  }
  &:hover {
    background-color: #30a31c;
    & > span {
      color: #fff;
      &::before {
        background-image: url("../img/icon/img_icon_pdf_hover.png");
      }
    }
  }
}
.article-request-btn{
  width: 100%;
  max-width: 400px;
  & > span {
    font-size: 16px;
    padding: 16px 0;
    display: block;
  }
}
.article-download-btn--pdf{
  padding: 8px 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & > span {
    line-height: 1;
    font-size: 12px;
    padding: 8px 0 8px 15px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      background-image: url("../img/icon/img_icon_pdf.png");
      background-size: contain;
      width: 12px;
      height: 14px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: background-image 0.2s;
    }

  }
}

.article-controller {
  display: flex;
  position: relative;
  margin-top: 50px;
  @include mq(u-sp){
    margin-top: 46px;
  }
}

.article-controller-btn-prev,
.article-controller-btn-all,
.article-controller-btn-next {
  font-size: 16px;
  @include mq(u-sp){
    font-size: 14px;
  }
}

.article-controller-btn-prev {
  padding-left: 40px;
  margin-right: auto;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    background-image: url("../img/icon/img_icon_prev.png");
    background-size: contain;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all 0.2s;
  }
  &:hover {
    &::before {
      background-image: url("../img/icon/img_icon_prev_hover.png");
    }
  }
}

.article-controller-btn-next {
  padding-right: 40px;
  margin-left: auto;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    background-image: url("../img/icon/img_icon_next.png");
    background-size: contain;
    width: 32px;
    height: 32px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: all 0.2s;
  }
  &:hover {
    &::after {
      background-image: url("../img/icon/img_icon_next_hover.png");
    }
  }
}

.article-controller-btn-all {
  position: absolute;
  left: 50%;
  padding-left: 40px;
  transform: translateX(-50%);
  &::before {
    position: absolute;
    content: "";
    background-image: url("../img/icon/img_icon_archive.png");
    background-size: contain;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: opacity 0.2s;
  }
  &:hover {
    &::before {
      opacity: 0.7;
    }
  }
}
