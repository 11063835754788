.news-lists {
  margin-top: 40px;
}

.news-lists-item {
  border-top: 1px solid #d6dbdf;
  padding: 24px 0;
  &:last-child {
    border-bottom: 1px solid #d6dbdf;
  }
}

.news-info {
  display: flex;
  gap: 24px;
  align-items: center;
}

.news-info-date {
  font-family: "quicksand";
  font-size: 14px;
  color: #000000;
}

.news-info-categorys {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  & > a {
    display: inline-block;
    border: 1px solid #58b947;
    padding: 0 4px;
    font-size: 12px;
    color: #58b947;
  }
}

.news-lists-item-headlines {
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
