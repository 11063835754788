.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 60px;
  z-index: 10;
  transition: background-color 0.2s;
  &.is-coloring {
    background-color: #fff;
  }
  @include mq(u-sp) {
    padding: 15px 20px;
  }
}

.header-pc {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.header-logo {
  display: block;
  width: 234px;
  transition: width 0.2s;
  @include mq(u-sp) {
    position: relative;
    z-index: 12;
    width: 134px;
  }
}

@include mq(o-sp) {
  .header.is-coloring .header-logo {
    width: 164px;
  }
}
.header-contact {
  display: flex;
  justify-content: end;
  gap: 40px;
  margin-bottom: 12px;
  @include mq(u-sp) {
    flex-direction: column-reverse;
  }
}

.header-contact_info {
  display: flex;
  align-items: center;
  gap: 16px;
  & > a {
    font-weight: bold;
    font-size: 24px;
    padding-left: 22px;
    color: #34495e;
    position: relative;
    font-family: "quicksand";
    &::before {
      position: absolute;
      content: "";
      background-image: url("../img/header/img_header_tel.png");
      background-size: contain;
      width: 18px;
      height: 18px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  & > p {
    font-size: 14px;
    color: #34495e;
  }
  @include mq(u-sp) {
    flex-direction: column;
    gap: 8px;
    & > a {
      font-size: 32px;
      line-height: 24px;
      padding-left: 28px;
      &::before {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.header-contact_btn_wrap {
  display: flex;
  gap: 10px;
  @include mq(u-sp) {
    flex-direction: column;
    padding: 0 20px;
  }
}

// .header-nav {
//   margin-top: 12px;
// }

.header-nav_list {
  display: flex;
  justify-content: end;
  gap: 32px;
}

.header-nav_list_item {
  font-size: 14px;
  color: #4c4c4c;
}

.header-contact_btn--login,
.header-contact_btn--inquiry {
  width: 144px;
  box-sizing: border-box;
  border-radius: 80px;
  font-size: 16px;
  display: inline-block;
  padding: 8px;
  text-align: center;
  transition: background-color 0.2s, color 0.2s;
  @include mq(u-sp) {
    width: 100%;
    padding: 16px 0;
  }
}

.header-contact_btn--login {
  border: 2px solid #30a31c;
  color: #30a31c;
  &:hover {
    background-color: #30a31c;
    color: #fff;
  }
}

.header-contact_btn--inquiry {
  border: 2px solid #e77a3d;
  background-color: #e77a3d;
  color: #fff;
  &:hover {
    background-color: #eea277;
    border: 2px solid #eea277;
  }
}

.header-menu-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 100%;
  background-color: #58b947;
  border-radius: 0px 0px 0px 20px;
  z-index: 13;
  cursor: pointer;
}

.header-modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 11;
  padding-top: 88px;
}

.header-modal-list {
  padding: 0 20px 24px;
  & > li {
    padding: 16px 0;
    font-size: 16px;
    color: #4c4c4c;
    &:not(:last-child) {
      border-bottom: 1px solid #f5f6f7;
    }
    & > a {
      position: relative;
      display: block;
      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        background-image: url("../img/header/img_header_next_sp.png");
        background-size: cover;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.header-modal-banner {
  display: flex;
  gap: 16px;
  padding: 40px 20px;
  background-color: #f5f6f7;
}

.header-modal-banner-card {
  padding: 16px 24px;
  width: calc(50% - 8px);
  background: #fff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.header-modal-banner-card-image {
  width: 60px;
  margin: 0 auto;
}

.header-modal-banner-card-translate {
  margin-top: 8px;
  text-align: center;
  font-family: "quicksand";
  font-size: 12px;
  line-height: 15px;
  color: #34495e;
}

.header-modal-banner-card-heading {
  margin-top: 8px;
  font-family: "zen-maru-gothic";
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #34495e;
}

.header-modal-content {
  height: calc(100vh - 88px);
  overflow: scroll;
}

.header-modal-contact {
  padding: 40px 0 108px;
}

.header-menu-btn-cross {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 8px auto 0;
  & > span {
    position: absolute;
    width: 30px;
    height: 1px;
    background-color: #fff;
    transition: all 0.2s;
    &:first-child {
      top: 10px;
    }
    &:last-child {
      bottom: 10px;
    }
  }
  &.is-open > span {
    &:first-child {
      top: 0;
      transform: rotate(45deg) translateY(-50%);
      top: 50%;
    }
    &:last-child {
      bottom: 0;
      transform: rotate(-45deg) translateY(-50%);
      top: 50%;
    }
  }
}

.header-menu-btn-text {
  position: absolute;
  left: 50%;
  bottom: 8px;
  transform: translateX(-50%);
  font-family: "quicksand";
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.header-news {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #34495e;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header-news-item {
  display: flex;
  justify-content: center;
  gap: 24px;
  & > p {
    font-size: 24px;
    line-height: 35px;
    color: #fff;
  }
  & > a {
    display: inline-block;
    padding: 8px 24px;
    background-color: #fff;
    color: #34495e;
    border-radius: 80px;
    font-size: 14px;
  }
  @include mq(u-sp) {
    flex-direction: column;
    gap: 8px;
    text-align: center;
    align-items: center;
    & > p {
      font-size: 16px;
      line-height: 23px;
    }
    & > a {
      padding: 4px 24px;
    }
  }
}

.header-login-btn {
  position: absolute;
  background-color: #34495e;
  width: 128px;
  height: 64px;
  right: 0;
  top: 0;
  z-index: 12;
  border-radius: 0px 0px 0px 20px;
  display: inline-block;
  padding: 8px 12px;
}

.header-login-btn-inner {
  width: 40px;
  text-align: center;
  & > img {
    width: 14px;
  }
  & > .header-menu-btn-text {
    left: 34px;
    font-size: 10px;
    bottom: 6px;
  }
}
