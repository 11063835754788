.archive-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 24px;
  margin-top: 40px;
  @include mq(u-sp) {
    gap: 24px 16px;
    margin-top: 24px;
  }
}

.archive-item {
  width: calc(33.3% - 16px);
  @include mq(u-sp) {
    width: calc(50% - 8px);
  }
}

.archive-item-thumbnail {
  display: block;
  overflow: hidden;
  aspect-ratio: 121 / 78;
  border-radius: 4px;
  position: relative;
  background-color: #999;
  &.is-movie:after {
    position: absolute;
    content: "";
    background-image: url("../img/icon/img_icon_movie.png");
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    background-size: cover;
  }
  @include mq(u-sp) {
    &.is-movie:after {
      background-image: url("../img/icon/img_icon_movie_sp.png");
    }
  }
}

.archive-item-categorys {
  margin-top: 16px;
}

.archive-item-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.archive-item-categorys {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  & > a {
    border: 1px solid #58b947;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0 4px;
    font-size: 12px;
    color: #58b947;
  }
}

.archive-item-date {
  font-family: "quicksand";
  font-size: 12px;
  color: #999;
}

.archive-item-heading {
  margin-top: 4px;
  font-size: 18px;
  line-height: 26px;
  color: #3c495b;
  @include mq(u-sp) {
    font-size: 16px;
    line-height: 23px;
  }
}

.archive-item-target {
  margin-top: 16px;
  font-size: 12px;
}

.archive-item-tags {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  & > li {
    font-size: 12px;
    color: #30a31c;
  }
}

.pagenation {
  margin-top: 26px;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  @include mq(u-sp) {
    margin-top: 40px;
  }
}

.pagenation__prev,
.pagenation__next {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: relative;
  transition: color 0.2s, background-color 0.2s;
  border: 1px solid #58b947;
  box-sizing: border-box;
  border-radius: 50%;
  & > a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  &:hover {
    background-color: #30a31c;
    & span {
      color: #fff;
    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 6px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
  }
}

// svg->base64エンコード（エンコード元は/assets/icon に格納）
.pagenation__prev {
  &::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2214%22%20viewBox%3D%220%200%208%2014%22%20width%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m7%2013-6-6%206-6%22%20stroke%3D%22%2358b947%22%2F%3E%3C%2Fsvg%3E");
  }
  &:hover {
    &::before {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2214%22%20viewBox%3D%220%200%208%2014%22%20width%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m7%2013-6-6%206-6%22%20stroke%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
    }
  }
}

.pagenation__next {
  &::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2214%22%20viewBox%3D%220%200%208%2014%22%20width%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m1%201%206%206-6%206%22%20stroke%3D%22%2358b947%22%2F%3E%3C%2Fsvg%3E");
  }
  &:hover {
    &::before {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2214%22%20viewBox%3D%220%200%208%2014%22%20width%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m1%201%206%206-6%206%22%20stroke%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
    }
  }
}
// .pagenation__prev {
//   left: 0;
// }

// .pagenation__next {
//   right: 0;
// }

.pagenation__body {
  display: flex;
  gap: 16px;
  justify-content: center;
  & > li {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    transition: color 0.2s, background-color 0.2s;
    &.is-current {
      background-color: #30a31c;
      & span {
        color: #fff;
      }
    }
    &:hover {
      background-color: #30a31c;
      & span {
        color: #fff;
      }
    }
    & > a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }
    & span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "quicksand";
      font-size: 18px;
    }
  }
}
