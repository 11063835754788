@font-face {
  font-family: "quicksand";
  font-weight: normal;
  src: url("../fonts/Quicksand-Regular.woff");
}

@font-face {
  font-family: "zen-maru-gothic";
  font-weight: normal;
  src: url("../fonts/ZenMaruGothic-Regular.woff");
}

@font-face {
  font-family: "noto-sans-jp";
  font-weight: normal;
  src: url("../fonts/NotoSansJP-Regular.woff");
}

body {
  font-family: "noto-sans-jp", "Yu Gothic", "游ゴシック", YuGothic,
    "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif; /* 游ゴシック系 */
  //  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;  /* 游明朝系 */
  //  font-family: Hiragino Sans,"ヒラギノ角ゴシック",Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, "Droid Sans", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;  /* ヒラギノ角ゴ系 */
}
