.faq-question {
  position: relative;
  padding-left: 32px;
  font-size: 18px;
  line-height: 27px;
  color: #34495e;
  font-weight: bold;
  padding-right: 120px;
  &::before {
    position: absolute;
    content: "Q.";
    font-family: "Quicksand";
    font-weight: bold;
    font-size: 24px;
    left: 0;
    top: 0;
  }
  &.is-open::after {
    background-image: url("../img/icon/img_icon_minus.png");
  }
  &::after {
    position: absolute;
    content: "";
    background-image: url("../img/icon/img_icon_plus.png");
    background-size: cover;
    width: 25px;
    height: 25px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  @include mq(u-sp) {
    padding-right: 62px;
  }
}

.faq-answer {
  padding-left: 32px;
  font-size: 14px;
  line-height: 21px;
  color: #4c4c4c;
  position: relative;
  &::before {
    position: absolute;
    content: "A.";
    font-family: "quicksand";
    font-weight: bold;
    font-size: 24px;
    left: 0;
    top: 0;
    color: #58b947;
  }
}
