*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  width: 100%;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

dt,
dd {
  word-break: break-all;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f2f2f2;
}

footer {
  margin-top: auto;
}

.l-wrap {
  background-color: #f2f2f2;
}

@include mq(o-sp) {
  .header,
  .footer {
    min-width: 1160px;
  }
  .l-wrap {
    min-width: 1160px;
    background-color: #f2f2f2;
  }
}
/* デバッグ用 */
// body * {
//   outline: 1px dotted rgba(255, 0, 0, 0.2) !important;
// }
// body * * {
//   outline: 1px dotted rgba(0, 128, 0, 0.2) !important;
// }
// body * * * {
//   outline: 1px dotted rgba(255, 165, 0, 0.2) !important;
// }
// body * * * * {
//   outline: 1px dotted rgba(0, 0, 255, 0.2) !important;
// }
