.u-hidden {
  display: none !important;
}
.u-hidden-sp {
  @include mq(u-sp) {
    display: none !important;
  }
}

.u-hidden-sp-over {
  @include mq(o-sp) {
    display: none !important;
  }
}
