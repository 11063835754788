.about-wrap {
  padding-top: 124px;
  @include mq(u-sp) {
    padding-top: 72px;
  }
}

.about-fv {
  margin-top: 32px;
  width: 100%;
  min-height: 600px;
  background-image: url("../img/about/img_about_fv.jpg");
  background-size: 2000px;
  background-position-x: center;
  background-position-y: top;
  @media screen and (min-width: 2000px) {
    background-size: cover;
    background-position-y: 50%;
  }
  @include mq(u-sp) {
    margin-top: 14px;
    min-height: 480px;
    background-size: 1576px;
  }
}

.about-fv-img {
  width: 295px;
  margin: 0 auto;
  padding-top: 88px;
  @include mq(u-sp) {
    padding-top: 48px;
    width: 204px;
  }
}

.about-fv-heading {
  margin-top: 12px;
  text-align: center;
  font-family: "zen-maru-gothic";
  font-size: 48px;
  line-height: 72px;
  color: #4c4c4c;
  @include mq(u-sp) {
    margin-top: 16px;
    font-size: 32px;
    line-height: 44px;
  }
}

.about-contents {
  display: flex;
  flex-direction: column;
  gap: 120px;
  margin-top: 104px;
  width: 100%;
  overflow: hidden;
  @include mq(u-sp) {
    margin-top: 51px;
    gap: 75px;
  }
}

.about-intro {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.about-intro-first {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 692px;
    height: 692px;
    background-color: #e5e5e5;
    border-radius: 50%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  & > h2 {
    position: relative;
    z-index: 2;
    font-family: "zen-maru-gothic";
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #4c4c4c;
    font-weight: bold;
  }
  @include mq(u-sp) {
    &::before {
      display: none;
    }
    & > h2 {
      font-size: 32px;
      line-height: 44px;
    }
  }
}

.about-intro-first-img {
  margin: 20px auto 0;
  width: 918px;
  position: relative;
  z-index: 2;
  @include mq(u-sp) {
    width: 100%;
    height: 752px;
    & > img {
      position: absolute;
      width: 579px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.about-intro-second {
  & > h2 {
    font-family: "zen-maru-gothic";
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #4c4c4c;
    font-weight: bold;
    & span {
      display: inline-block;
      width: 348px;
    }
  }
  @include mq(u-sp) {
    & > h2 {
      font-size: 32px;
      line-height: 54px;
      & span {
        width: 272px;
      }
    }
  }
}

.about-intro-second-contents {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @include mq(u-sp) {
    flex-direction: column;
    gap: 16px;
    padding: 20px;
  }
}

.about-intro-second-img {
  width: 645px;
  @include mq(u-sp) {
    width: 100%;
  }
}

.about-intro-second-contents-text {
  width: 360px;
  & > h3 {
    font-family: "zen-maru-gothic";
    font-size: 36px;
    line-height: 54px;
    color: #4c4c4c;
  }
  & > p {
    margin-top: 24px;
    font-size: 18px;
    line-height: 27px;
    color: #4c4c4c;
  }
  @include mq(u-sp) {
    width: 100%;
    & > h3 {
      font-size: 24px;
      line-height: 36px;
    }
    & > p {
      font-size: 16px;
      line-height: 27px;
      margin-top: 16px;
    }
  }
}

.about-contact {
  display: flex;
  align-items: center;
  gap: 64px;
  @include mq(u-sp) {
    flex-direction: column;
    padding-right: 20px;
    gap: 50px;
  }
}

.about-contact-slider {
  width: 54%;
  border-radius: 0px 336px 336px 0px;
  background-color: #4c4c4c;
  height: 672px;
  overflow: hidden;
  position: relative;
  max-width: 1020px;
  & div {
    right: 0;
    width: 1020px;
  }
  @include mq(u-sp) {
    width: 100%;
    border-radius: 0px 336px 336px 0px;
    height: 324px;
    & > div {
      position: absolute;
      right: 0;
      width: 724px;
      & > img {
      }
    }
  }
}

.jsc-slider-item {
  position: absolute;
}

.about-contact {
  max-width: 2000px;
  margin: 0 auto;
  width: 100%;
}
.about-contact-content {
  width: 520px;
  & > h3 {
    font-family: "zen-maru-gothic";
    font-weight: bold;
    font-size: 48px;
    line-height: 70px;
    color: #000000;
    & span {
      display: inline-block;
      width: 310px;
    }
  }
  & > p {
    margin-top: 16px;
    font-size: 14px;
    line-height: 21px;
    color: #000;
  }
  @include mq(u-sp) {
    padding-left: 20px;
    width: 100%;
    & > h3 {
      font-size: 32px;
      line-height: 46px;
      & span {
        width: 204px;
      }
    }
  }
}

.about-point {
  padding: 154px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 2191px;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @include mq(u-sp) {
    padding: 80px 20px;
    &::before {
      width: 2400px;
    }
  }
}

.about-point-inner {
  position: relative;
  z-index: 2;
  max-width: 1040px;
  margin: 0 auto;
}

.about-point-heading {
  font-family: "zen-maru-gothic";
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: #4c4c4c;
  text-align: center;
  & > span {
    display: inline-block;
    width: 244px;
    margin-right: 8px;
  }
  @include mq(u-sp) {
    font-size: 32px;
    line-height: 54px;
    & > span {
      width: 198px;
    }
  }
}

.about-point-column {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 72px;
  @include mq(u-sp) {
    gap: 40px;
  }
}

.about-point-column-item {
  display: flex;
  align-items: center;
  gap: 78px;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  @include mq(u-sp) {
    flex-direction: column;
    gap: 30px;
    &:nth-child(even) {
      flex-direction: column;
    }
  }
}

.about-point-column-item-content {
  width: 280px;
  @include mq(u-sp) {
    width: 100%;
  }
}

.about-point-column-item-index {
  font-family: "quicksand";
  font-weight: bold;
  font-size: 72px;
  line-height: 90px;
  color: #58b947;
}

.about-point-column-item-heading {
  margin-top: 16px;
  font-family: "zen-maru-gothic";
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #4c4c4c;
}

.about-point-column-item-subheading {
  margin-top: 16px;
  font-family: "zen-maru-gothic";
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #4c4c4c;
}

.about-point-column-item-text {
  margin-top: 8px;
  font-size: 14px;
  line-height: 21px;
  color: #4c4c4c;
}

.about-point-column-item-content-img {
  width: 682px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: calc(100% - 136px);
    background-color: #fff;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #f2f2f2;
    border-radius: 8px;
  }
  @include mq(u-sp) {
    width: 100%;
    &::before {
      width: calc(100% + 40px);
      height: calc(70%);
      left: -20px;
      border-radius: 0;
    }
  }
}

.about-frame-first,
.about-frame-second,
.about-frame-third {
  position: relative;
  z-index: 2;
  margin: 0 auto;
}

.about-frame-first {
  width: 595px;
  & > video {
    width: 229px;
    margin-left: 177px;
    padding-top: 39px;
  }
  @include mq(u-sp) {
    width: 100%;
    max-width: 595px;
    & > video {
      padding-top: 0;
      width: 38.5%;
      margin-left: 29.7%;
      max-width: 229px;
      aspect-ratio: 229 / 431;
      transform: translateY(4.5%);
    }
  }
}

.about-frame-first-frame {
  position: absolute;
  z-index: 3;
  top: 0;
  margin: 0 auto;
}

.about-frame-second {
  width: 589px;
  & > video {
    width: 230px;
    margin-left: 14px;
    padding-top: 39px;
  }
  @include mq(u-sp) {
    width: 100%;
    max-width: 589px;
    & > video {
      padding-top: 0;
      width: 39%;
      margin-left: 2.5%;
      max-width: 229px;
      aspect-ratio: 229 / 431;
      transform: translateY(4.5%);
    }
  }
}

.about-frame-second-frame {
  position: absolute;
  z-index: 3;
  top: 0;
  margin: 0 auto;
}

.about-frame-third {
  width: 418px;
  & > video {
    width: 229px;
    margin-left: 176px;
    padding-top: 39px;
  }
  @include mq(u-sp) {
    width: 100%;
    max-width: 418px;
    & > video {
      padding-top: 0;
      width: 55%;
      margin-left: 42%;
      margin-right: 2.5%;
      max-width: 229px;
      aspect-ratio: 229 / 431;
      transform: translateY(4%);
    }
  }
}

.about-frame-third-frame {
  position: absolute;
  z-index: 3;
  top: 0;
  margin: 0 auto;
}

.about-contact-conductor {
  margin-top: 40px;
  text-align: center;
  & > p {
    padding: 0 20px;
    display: inline-block;
    position: relative;
    font-family: "zen-maru-gothic";
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #34495e;
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 14px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      background-size: contain;
    }
    &::before {
      left: 0;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2216%22%20height%3D%2222%22%20viewBox%3D%220%200%2016%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%201L15%2021%22%20stroke%3D%22%2334495E%22%2F%3E%3C%2Fsvg%3E");
    }
    &::after {
      right: 0;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2216%22%20height%3D%2222%22%20viewBox%3D%220%200%2016%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15%201L1%2021%22%20stroke%3D%22%2334495E%22%2F%3E%3C%2Fsvg%3E");
    }
  }
}

.about-contact-btn-wrap {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about-contact-btn-inquiry {
  padding: 22px 10px;
  text-align: center;
  background-color: #e77a3d;
  border-radius: 40px;
  font-size: 24px;
  color: #fff;
  & > span {
    padding-left: 46px;
    padding-right: 90px;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      background-image: url("../img/about/img_icon_pc.png");
      background-size: contain;
      width: 38px;
      height: 24px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &::after {
      position: absolute;
      content: "24時間受付中";
      font-size: 12px;
      line-height: 16px;
      color: #fff;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      padding: 3px;
      border: 1px solid #fff;
    }
  }
  @include mq(u-sp) {
    font-size: 20px;
    line-height: 26px;
    padding: 15px 10px;
    & > span {
      display: inline-block;
      padding-right: 55px;
      &::after {
        content: "24時間\A受付中";
        white-space: pre;
      }
    }
  }
}

.about-contact-btn-tel {
  padding: 16px 10px;
  background-color: #ffffff;
  border: 2px solid #58b947;
  box-sizing: border-box;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @include mq(u-sp) {
    flex-direction: column-reverse;
    padding: 12px 10px;
    gap: 4px;
  }
}

.about-contact-btn-tel-info {
  & > div:first-child {
    font-size: 16px;
    line-height: 23px;
    color: #58b947;
  }
  & > div:last-child {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #58b947;
  }
  @include mq(u-sp) {
    display: flex;
    align-items: center;
    gap: 4px;
    & > div:first-child {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.about-contact-btn-tel-num {
  font-family: "quicksand";
  font-weight: bold;
  font-size: 36px;
  color: #58b947;
  padding-left: 28px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    background-image: url("../img/icon/img_icon_tel_green.png");
    background-size: contain;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  @include mq(u-sp) {
    font-size: 24px;
    line-height: 30px;
  }
}
