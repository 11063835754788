.footer {
  background-color: #34495e;
  border-radius: 40px 40px 0px 0px;
  margin-top: 124px;
  position: relative;
}

.footer-scroll-btn {
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #58b947;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  padding: 14px 16px;
  top: 0px;
  right: 120px;
  transform: translateY(-50%);
  @include mq(u-sp) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.footer_inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 60px;
  gap: 20px 0;
  @include mq(u-sp) {
    padding: 60px 40px;
    gap: 36px;
  }
  &--top {
    padding-bottom: 108px;
    @include mq(u-sp) {
      padding-bottom: 108px;
    }
  }
}

.footer_content {
  min-width: 50%;
  @include mq(u-sp) {
    min-width: 100%;
  }
}

.footer_logo {
  width: 190px;
  @include mq(u-sp) {
    width: 178px;
    margin: 0 auto;
  }
}

.footer_nav {
  display: flex;
  gap: 64px;
  margin-top: 26px;
  @include mq(u-sp) {
    flex-direction: column;
    gap: 24px;
  }
}

.footer_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include mq(u-sp) {
    gap: 24px;
  }
}

.footer_list_item {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.footer_contact {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  @include mq(u-sp) {
    flex-direction: column;
    width: 100%;
  }
}

.footer_contact_info {
  & > a {
    font-size: 24px;
    color: #fff;
    padding-left: 22px;
    position: relative;
    font-family: "quicksand";
    &::before {
      position: absolute;
      content: "";
      background-image: url("../img/footer/img_footer_tel.png");
      background-size: contain;
      width: 18px;
      height: 18px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  & > p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    color: #fff;
  }
  @include mq(u-sp) {
    margin: 0 auto;
    & > a {
      font-size: 32px;
      line-height: 24px;
      color: #fff;
      padding-left: 24px;
      &::before {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
      }
    }
  }
  & > p {
    text-align: center;
  }
}

.footer_contact_btn {
  display: inline-block;
  padding: 8px 36px;
  background: #fff;
  border-radius: 80px;
  font-size: 16px;
  color: #34495e;
  transition: background-color 0.2s;
  &:hover {
    background-color: #aeb6bf;
  }
  @include mq(u-sp) {
    width: 100%;
    text-align: center;
  }
}

.footer_copy {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  width: 100%;
  @include mq(u-sp) {
    text-align: center;
  }
}
