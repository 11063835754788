.sidemenu {
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include mq(u-sp) {
    width: 100%;
  }
}

.sidemenu-item {
  box-sizing: border-box;
  border-top: 2px solid #34495e;
  padding-top: 12px;
  @include mq(u-sp) {
    padding-top: 24px;
  }
}

.sidemenu-title {
  padding: 0 16px;
  font-size: 18px;
  font-family: "zen-maru-gothic";
  font-weight: bold;
}

.sidemenu-category {
  margin-top: 16px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include mq(u-sp) {
    margin-top: 24px;
    line-height: 32px;
    padding: 8px 16px;
    gap: 0;
  }
}

.sidemenu-category-item {
  font-size: 14px;
  font-weight: bold;
  &.is-current {
    & > a {
      color: #30a31c;
    }
  }
  & > a {
    padding-left: 10px;
    position: relative;
    transition: color 0.2s;
    &::before {
      content: "・";
    }
    &:hover {
      color: #30a31c;
    }
  }
}

.sidemenu-keyword {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  @include mq(u-sp) {
    margin-top: 24px;
  }
}

.sidemenu-keyword-item {
  background-color: #fff;
  border-radius: 8px;
  font-size: 14px;
  transition: color 0.2s, background-color 0.2s;
  & > a {
    display: block;
    padding: 4px;
    color: #000000;
  }
  &:hover,
  &.is-current {
    background-color: #30a31c;
    & > a {
      color: #fff;
    }
  }
}

.sidemenu-conductor-card {
  display: block;
  background: #fff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px 26px;
  & > p {
    font-family: "zen-maru-gothic";
    margin-top: 8px;
    text-align: center;
    font-size: 24px;
    color: #34495e;
  }
  @include mq(u-sp) {
    width: calc(50% - 8px);
    padding: 24px 14px;
    & > p {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.sidemenu-conductor-card-icon {
  width: 80px;
  margin: 0 auto;
}

.sidemenu-conductor-card-translate {
  font-family: "quicksand";
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #34495e;
  margin-top: 8px;
  @include mq(u-sp) {
    font-size: 12px;
    line-height: 15px;
  }
}

.sidemenu-conductor {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include mq(u-sp) {
    flex-direction: row;
    gap: 16px;
  }
}

.sidemenu-articles {
  margin-top: 16px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
}

.sidemenu-articles-item {
  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #d6dbdf;
  }
  &:not(:first-child) {
    padding-top: 16px;
  }
}

.sidemenu-articles-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidemenu-articles-date {
  font-family: "quicksand";
  font-size: 12px;
  color: #999999;
}

.sidemenu-articles-category {
  font-size: 12px;
  color: #58b947;
}

.sidemenu-articles-headline {
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  color: #4c4c4c;
}
